/**
 * Checks if <select> tags has a selected option (if the select tag has a placeholder as first option)
 */
export function initializeExclusiveOffersList () {

    const exclusiveOffersListModules = document.querySelectorAll('.exclusive-offers-list')

    if (exclusiveOffersListModules.length > 0) {

        const toggleViewMode = (exclusiveOffersListModule, viewToggle) => {
            let viewMode = viewToggle.getAttribute('data-view'),
                viewToggles = exclusiveOffersListModule.querySelectorAll('.toggle-view-btn')
            exclusiveOffersListModule.classList.remove('view-mode-' + exclusiveOffersListModule.getAttribute('data-view'))
            exclusiveOffersListModule.classList.add('view-mode-' + viewMode)
            exclusiveOffersListModule.setAttribute('data-view', viewMode)
            viewToggles.forEach(viewToggle => {
                viewToggle.classList.remove('active')
            })
            viewToggle.classList.add('active')
        }

        let vehiclesData

        const getVehiclesData = (exclusiveOffersListModule) => {
            let vehicles = exclusiveOffersListModule.querySelectorAll('.vehicle'),
                vehiclesList = []
            vehicles.forEach(vehicle => {
                let vehicleData = {
                    id: '',
                    filters : {}
                  },
                  dataSet = vehicle.dataset,
                  dataObject = Object.assign({}, dataSet)

                  Object.entries(dataObject).map(entry => {
                      vehicleData['filters'][entry[0]] = entry[1]
                  })

                vehicleData['id'] = vehicle.id
                vehiclesList.push(vehicleData)
            })
            return vehiclesList
        }

        const getFilters = (exclusiveOffersListModule, filters, vehiclesData) => {

            let filtersList = []
            filters.forEach(filter => {
                filtersList[filter.getAttribute('data-key')] = filter.value
            })

            let validatedList = []

            vehiclesData.forEach(vehicleData => {
                let validateForDisplay = true
                for (const [key, value] of Object.entries(filtersList)) {
                    if (validateForDisplay === false) {
                        return
                    } else if (!(value === 'no-selection')) {
                        if (!(vehicleData['filters'][key] === value)) {
                            validateForDisplay = false
                        }
                    }
                }
                if (validateForDisplay === true) {
                    validatedList.push(vehicleData['id'])
                }
            })
            updateDisplay(exclusiveOffersListModule, validatedList)
        }

        const updateDisplay = (exclusiveOffersListModule, validatedList) => {
            let vehicles = exclusiveOffersListModule.querySelectorAll('.vehicle')

            let displayedVehicles = 0
            vehicles.forEach(vehicle => {
                vehicle.style.display = 'none'
            })
            validatedList.forEach(vehicleId => {
                let vehicle = document.getElementById(vehicleId)
                vehicle.style.display = 'flex'
                displayedVehicles++
            })
            if (!displayedVehicles) {
                document.querySelector('.empty-vehicle-results').classList.remove('hide')
            } else {
                document.querySelector('.empty-vehicle-results').classList.add('hide')
            }
        }

        const initResetFiltersButton = () => {
            let button = document.querySelector('.empty-vehicle-results button.reset-vehicle-filters')
            button.addEventListener('click', function () {
                document.querySelectorAll('.filters-list select').forEach(select => {
                    select.value = 'no-selection'
                    select.dispatchEvent(new Event('change'))
                })
            })
        }

        exclusiveOffersListModules.forEach(exclusiveOffersListModule => {
            vehiclesData = getVehiclesData(exclusiveOffersListModule)
            const filters = exclusiveOffersListModule.querySelectorAll('.filter select'),
                  viewToggles = exclusiveOffersListModule.querySelectorAll('.toggle-view-btn')

            viewToggles.forEach(viewToggle => {
                viewToggle.addEventListener('click', () => {
                    toggleViewMode(exclusiveOffersListModule, viewToggle)
                })
            })

            filters.forEach(filter => {
                filter.addEventListener('change', () => {
                    getFilters(exclusiveOffersListModule, filters, vehiclesData)
                })
            })
            initResetFiltersButton()
        })
    }
}
