import Glide from '@glidejs/glide'
/**
 * Initialize showroom carousels
 */
export function initializeShowroomCarousels() {
	let glideSliders = []
	const initCarousel = (offer) => {
		let showroomCarousel = offer.querySelector('.showroom-glide')
		let glide = new Glide(showroomCarousel, {
			perView: 1,
			gap: 0,
		})
		glide.mount()
		window.dispatchEvent(new Event('resize'))
	}

	const showroomOffers = document.querySelectorAll('.exclusive-offers-showroom-offer')

	if (showroomOffers.length) {
		showroomOffers.forEach((offer) => {
			// initialize slider for first offer
			if (offer.classList.contains('current')) {
				initCarousel(offer)
			}
			// initialize buttons events
			let motorizationTogglers = offer.querySelectorAll('.exclusive-offer-switcher button')
			motorizationTogglers.forEach((toggler) => {
				toggler.addEventListener('click', () => {
					let nextOffer = document.querySelector('.exclusive-offers-showroom-offer:not(.current)')
					document.querySelector('.exclusive-offers-showroom-offer.current').classList.remove('current')
					nextOffer.classList.add('current')
					initCarousel(nextOffer)
				})
			})
		})
	}
}
