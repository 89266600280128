// const throttle = require('lodash/throttle')

/**
 * Checks if <select> tags has a selected option (if the select tag has a placeholder as first option)
 */
export function initializeSelectCheckers () {

    const selects = document.querySelectorAll('select[selection-check]')

    if (selects.length > 0) {

        const checkSelection = (select) => {
            const value = select.value
            select.classList[value && (value === 'no-selection' || value === '') ? 'remove' : 'add']('has-selection')
        }

        selects.forEach(select => {
            checkSelection(select)
            select.addEventListener('change', () => {
                checkSelection(select)
            })
        })
    }
}

