import Glide from '@glidejs/glide'

/**
 * Initialize articles carousel (only visible on mobile)
 */
export function initializeCarousels() {

  const carousels = document.querySelectorAll('.texts-glide')

  if (carousels) {

    // Adapt height function as a component ; code found here : https://github.com/glidejs/glide/issues/236
    function GlideAutoHeight(Glide, Components, Events) {
      const Component = {
        mount() {
          if (!Glide.settings.autoHeight) return
          Components.Html.track.style.transition = 'height 300ms ease-in-out'
          this.updateTrackHeight()
        },

        updateTrackHeight() {
          if (!Glide.settings.autoHeight) return

          const activeSlides = Components.Html.slides.filter((slide, index) => {
            return (index >= Glide.index && index <= (Glide.index-1) + Glide.settings.perView)
          })

          const newMaxHeight = activeSlides.reduce((maxHeight, slide) => {
            return Math.max(maxHeight, slide.offsetHeight)
          }, 0)

          const glideTrack = Components.Html.track
          if (newMaxHeight !== glideTrack.offsetHeight) {
            glideTrack.style.height = `${newMaxHeight}px`
          }
        },
      }

      Events.on('run', () => {Component.updateTrackHeight()})
      Events.on('update', () => {Component.updateTrackHeight()})
      Events.on('resize', () => {Component.updateTrackHeight()})

      return Component
    }

    carousels.forEach(carousel => {

      let glide = new Glide(carousel, {
        autoHeight: true,
        perView: 2.25,
        gap: 0,
        breakpoints: {
          768: {
            perView: 1,
          }
        }
      })
      glide.mount({
        GlideAutoHeight: GlideAutoHeight
      })
    })
  }
}
